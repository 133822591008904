import styled from "@emotion/styled";
import { Grid } from "@mui/material";

const TWrapper = styled.div`
border-radius: 20px;
background-color: ${(props) => (props.bgcolor ? props.bgcolor : "#191313")};
background-image: ${(props) => (props.bgimg ? props.bgimg : "")} ;
display: flex;
justify-content: space-between;
padding: ${(props) => (props.p ? props.p : "20px 45px")};
border: ${(props) => (props.border ? props.border : "")};
`
const Name = styled.div`
font-family: "Poppins-SemiBold";
color: ${(props) => (props.Namecolor ? props.Namecolor : "#fff")};
`
const Value = styled.a`
font-family: "Poppins-SemiBold";
color: ${(props) => (props.color ? props.color : "#FBFB07")};

`
const MainGrid = styled(Grid)`
padding: 30px 0;
`
const Textarea = styled.textarea`
font-family: "Poppins-SemiBold";
  font-size: 16px;
  resize: none;
  overflow: hidden;
  border: none;
  background: transparent;
  text-align: -webkit-center;
 width: 40px;
  /* width: 400px; */
  outline: none;
  @media screen and (max-width: 599px) {
    /* width: 170px; */
  }
`;
const ContractAdd = styled.span`
  word-break: keep-all;
  place-content: center;
  display: flex;
  align-items: CENTER;
  @media screen and (max-width: 1200px) {
    /* display: grid; */
  }
`;
export { TWrapper, Name, Value, MainGrid, Textarea, ContractAdd }