import styled from "@emotion/styled";

const PageWrapper = styled.div`
  background-color: ${(props) => props.theme.pageColor};
  min-height: 100vh;
`;

const SectionWrapper = styled.div`
  background-color: ${(props) => (props.bgColor ? props.bgColor : "#010000")};
  padding: ${(props) => (props.p ? props.p : "0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  @media screen and (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "30px 0")};
  }
  @media screen and (max-width: 599px) {
    padding: ${(props) => (props.p599 ? props.p599 : "30px 0")};
  }
`;
const P = styled.p`
  font-weight: ${(props) => (props.fw ? props.fw : "")};
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: #fff;
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.p ? props.p : "")};
  @media (max-width: 600px) {
    font-size: ${(props) => (props.fs600px ? props.fs600px : "")};
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
  `;
const MainPageHeading = styled.div`
font-family: "Thuoc";
text-align: ${(props) => (props.align ? props.align : "")};
font-size: ${(props) => (props.fs ? props.fs : "80px")};
  color: ${(props) => props.theme.textOpposite};
  padding: ${(props) => (props.p ? props.p : "")};
  margin: ${(props) => (props.m ? props.m : "0")};  
  text-transform: uppercase;
  @media screen and (max-width:599px){
    font-size: 60px;
  }
`
const MainPageHeadingSubHeading = styled.div`
  font-size: 20px;
  font-family: "Poppins-Bold";
  color: ${(props) => props.theme.textOpposite};
 
`
const MainPageHeadingSubCatHeading = styled.span`
  font-family: "Poppins-Bold";
  color: ${(props) => props.theme.textOpposite};
font-size: ${(props) => (props.fs ? props.fs : "")};
`

const CustomContainer = styled.div`
  max-width: 2600px;
`;
const Heading = styled.div`
  font-size: ${(props) => (props.fs ? props.fs : "20px")};
  color: ${(props) => props.theme.textTertiary};
  padding: ${(props) => (props.p ? props.p : "")};
  margin: ${(props) => (props.m ? props.m : "0")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  font-family: "Poppins-SemiBold";
  
  @media (max-width: 899px) {
    display: ${(props) => (props.display ? props.display : "")};
  }
  @media (max-width: 599px) {
    font-size: 20px;
    line-height: 30px;
  }
`;
const SectionHeading = styled.h2`
text-transform: uppercase;
background: #23EBA7;
 background: ${(props) => (props.bg? props.bg : "linear-gradient(to bottom right, #23EBA7 0%, #127654 100%)")};
-webkit-background-clip: text;
-webkit-text-fill-color: transparent;
  font-size: ${(props) => (props.fs ? props.fs : "45px")};
  font-family: "Thuoc";
  padding: ${(props) => (props.p ? props.p : "10px 0")};
  text-align: ${(props) => (props.align ? props.align : "")};
  margin: ${(props) => (props.m ? props.m : "0")};
  @media screen and (max-width: 1199px) {
    /* font-size: 40px; */
  }
  @media screen and (max-width: 599px) {
    font-size: ${(props) => (props.fs599 ? props.fs599 : "25px")};
    line-height: ${(props) => (props.lh599 ? props.lh599 : "27px")};
  }
`;
const SectionSubHeading = styled.h3`
  font-family: "poppins-Bold";
  font-size: ${(props) => (props.fs ? props.fs : "35px")};
  color: ${(props) => props.theme.textPrimary};
  padding: ${(props) => (props.p ? props.p : "0px 0 0px 0")};
  margin: ${(props) => (props.m ? props.m : "0")};
  line-height: ${(props) => (props.lh ? props.lh : "50px")};
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};
  @media screen and (max-width: 1199px) {
    font-size: 30px;
  }
  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "35px")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "25px")};
  }
`;
const SectionSmallHeading = styled.h3`
  font-family: "Poppins-SemiBold";
  font-size: ${(props) => (props.fs ? props.fs : "20px")};
  color: #fff;
  padding: ${(props) => (props.p ? props.p : "0px ")};
  margin: ${(props) => (props.m ? props.m : "0")};
  /* line-height: ${(props) => (props.lh ? props.lh : "40px")}; */
  max-width: ${(props) => (props.mw ? props.mw : "100%")};
  text-align: ${(props) => (props.align ? props.align : "")};

  /* @media (max-width: 799px) {
    padding: ${(props) => (props.p799 ? props.p799 : "")};
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "40px")};
  }
  @media (max-width: 599px) {
    line-height: ${(props) => (props.lh ? props.lh : "")};
    max-width: 100%;
    font-size: ${(props) => (props.fs599 ? props.fs599 : "")};
  } */
`;
const FooterImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 50px;
`;

const LogoText = styled.div`
  color: ${(props) => props.theme.textPrimary};
  font-family: "Poppins-Bold";
  font-size: 12px;
  /* line-height: 21px; */
`;
const LogoImage = styled.a`
  display: flex;
  gap: 13px;
  align-items: center;
`;
const ValueToken = styled.span`
color: ${(props) => props.theme.textTertiary};
`
export {FooterImage,LogoText,LogoImage,PageWrapper,SectionWrapper,P,SectionHeading,SectionSubHeading,CustomContainer,SectionSmallHeading,Heading,ValueToken,MainPageHeading,MainPageHeadingSubHeading,MainPageHeadingSubCatHeading,};
