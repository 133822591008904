import { ThemeProvider, createTheme } from "@mui/material";
import { useEffect, useState } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import { useSelector } from "react-redux";
import MainPage from "../Views/Pages/MainPage";

const getActiveTheme = (themeMode) => {
  console.log("themeMode: ", themeMode);
  return createTheme({
    pageColor: themeMode === "true" || !themeMode ? "#FBFBFB" : "#FBFBFB",

    bgPrimary: themeMode === "true" || !themeMode ? "#efefef" : "#efefef",
    bgSecondary: themeMode === "true" || !themeMode ? "#F5F5F5" : "#F5F5F5",
    bgTertiary: themeMode === "true" || !themeMode ? "#F5F5F5" : "#F5F5F5",
    BgQuaternary: themeMode === "true" || !themeMode ? "#FFFFFF" : "#FFFFFF",

    textPrimary: themeMode === "true" || !themeMode ? "#64626A" : "#64626A",
    textOpposite: themeMode === "true" || !themeMode ? "#ffffff" : "#ffffff",
    textSecondary: themeMode === "true" || !themeMode ? "#B3B1B7" : "#B3B1B7",
    textTertiary: themeMode === "true" || !themeMode ? "#3B3A40" : "#3B3A40",

    switchColor: themeMode === "true" || !themeMode ? "#16C186" : "#16C186",
  });
};
const ThemeContainer = () => {
  const currentTheme = useSelector((state) => state.LDTheme.value);
  const [activeTheme, setActiveTheme] = useState(getActiveTheme(currentTheme));

  useEffect(() => {
    setActiveTheme(getActiveTheme(currentTheme));
  }, [currentTheme]);
  return (
    <ThemeProvider theme={activeTheme}>
      <CssBaseline />
      <MainPage toggleTheme="" />
    </ThemeProvider>
  );
};

export default ThemeContainer;
