import styled from "@emotion/styled";
// import { FaTelegramPlane } from "react-icons/fa";
import { GrMedium } from "react-icons/gr";

import { AiOutlineTwitter } from "react-icons/ai";

const IconsTwitter = styled(AiOutlineTwitter)`
  color: ${(props) => props.theme.textPrimary};
  font-size: 27px;
`;
// const IconsTelegram = styled(FaTelegramPlane)`
//   color: ${(props) => props.theme.textPrimary};
//   font-size: 26px;
// `;
const IconsMedium = styled(GrMedium)`
  color: ${(props) => props.theme.textPrimary};
  font-size: 23px;
`;

const Contactus = styled.a`
  text-decoration: none;
  cursor: pointer;
  box-shadow: ${(props) =>
    props.bs ? props.bs : "0px 19px 50px 45px rgb(22 193 134 / 20%)"};
  background-color: #16c186;
  color: ${(props) => (props.color ? props.color : "#fff")};
  border-radius: 10px;
  padding: 8px 40px;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "5px 27px")};
  }
  &:hover {
    opacity: 0.9;
  }
`;
const SocialsDIv = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 30px 0;
  @media screen and (max-width: 689px) {
    /* display: block; */
    padding: 20px 0;
  }
`;
const SocialLinks = styled.a`
  color: ${(props) => props.theme.textPrimary};
  font-family: "Poppins-SemiBold";
  padding: 0 20px;
  text-decoration: none;
  @media screen and (max-width: 689px) {
    /* display: block; */
    /* padding: 10px 0; */
  }
`;
const MainDiv = styled.div`
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
    align-items: center;
  @media screen and (max-width: 899px) {
    /* display: block; */
  }
`;
const Copy = styled.div`
  padding: 20px 0;
  text-align: center;
  @media screen and (max-width: 799px) {
    /* border-top: 1px solid #fff; */
  }
`;
const Issue = styled.div`
display: flex;
justify-content: space-between;
  padding: 10px 0;
  /* text-align-last: center; */
  @media screen and (max-width:599px){
    display: block;
  }
`;
const SocialMedia = styled.div`
  display: flex;
  align-items: center;
`;
const Follow = styled.span`
  color: ${(props) => props.theme.textPrimary};
  font-family: Poppins-SemiBold;
`;
const SocialMediaLinks = styled.a`
  padding: 0 0 0 10px;
`;
const Single = styled.span`
  color: #000;
`;
const Wrapper = styled.div`
 padding: 5rem 0;
 background-color: #000; border-style: solid;
 border-width: 1px;
 border-top:1px solid #F9FB04 ;
 @media screen and (max-width:599px){
  padding: 2rem 0 0 0;

}
`;

export {
  IconsTwitter,
  // IconsTelegram,
  Contactus,
  SocialsDIv,
  SocialLinks,
  MainDiv,
  Copy,
  Issue,
  SocialMedia,
  Follow,
  SocialMediaLinks,
  Single,
  Wrapper,
  IconsMedium,
};
