import { Container, Grid } from "@mui/material";
import React from "react";


import { P, SectionHeading, SectionSmallHeading, SectionWrapper } from "../../../Styles/style";
import {WrapperGrid} from "./Styles";
import styled from "@emotion/styled";
import aboutHeading from "../../../../assets/images/aboutHeading.png"

import Jointelegram from "../../../../assets/images/jointelegram.png"


const SOcialImg = styled.img`
width:100%;
height: auto;
max-width: 100px;
`
const ReadMore = styled.a`
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "transparent")};
  margin-right: 20px;
  border-radius: 42px;
  padding: ${(props) => (props.p ? props.p : "7px 25px")};
  font-size: 14px;
  color: #fff;
  font-family: "Poppins-SemiBold";
  @media screen and (max-width: 899px) {
    /* padding: ${(props) => (props.p599 ? props.p599 : "5px 27px")}; */
  }
  &:hover {
    opacity: 0.9;
  }
  @media screen and (max-width:399px){
    margin: 5px 0;
    margin-right: 0px;
}
`;
const Heading = styled.img`
width:100%;
height: auto;
max-width: 500px;
`
const S2_Aboutus = () => {
  return (
    <div>
      <SectionWrapper p="5rem 0" id="about" align="center">
        <Container maxWidth="xl">
<Heading src={aboutHeading} />         <WrapperGrid container spacing={0} alignItems="center" justifyContent="center">
          <Grid item xs={12} md={8} sm={10}>
            <P align="Center">Bloki is more than just a meme token; it's a community-driven movement built on the foundation of the Blast Network. We believe in the power of decentralization and aim to empower individuals by providing them with accessible and affordable financial tools.
<br /><br />
Through community governance, we will work together to shape the future of Bloki and ensure its success. Our goal is to create a vibrant ecosystem that benefits everyone involved, from token holders to developers and enthusiasts. Together, we can build a "people's currency" that fosters financial inclusion and empowers individuals to take control of their financial future.</P>
          </Grid>
         </WrapperGrid>
         <ReadMore  href="https://t.me/blokiblast" target="_blank" p="15px 20px 3px 20px" bgcolor="#37AEE2"><SOcialImg src={Jointelegram}/> </ReadMore>
        </Container>
      </SectionWrapper>
    </div>
  );
};

export default S2_Aboutus;
