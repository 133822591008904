import React, { useRef, useState } from "react";
import { Container, Grid } from "@mui/material";
import copy from "../../../../assets/images/copy.png"

import { TWrapper, Name, Value, MainGrid, Textarea, ContractAdd } from "./styles";
import { SectionHeading, SectionSmallHeading, SectionWrapper } from "../../../Styles/style";
import styled from "@emotion/styled";
import tokenHeading from "../../../../assets/images/tokenHeading.png"

const Heading = styled.img`
width:100%;
height: auto;
max-width: 500px;
margin: 20px auto;
`
const Index = () => {
  const [copySuccess, setCopySuccess] = useState("");
  const textAreaRef = useRef(null);

  function copyToClipboard(e) {
    textAreaRef.current.select();
    document.execCommand("copy");
    // This is just personal preference.
    // I prefer to not show the whole text area selected.
    e.target.focus();
    setCopySuccess("Copied!");
  }
  return (
    <SectionWrapper style={{display:"flex",flexDirection:"column"}} p599="2rem 0px" p="4rem 0 12rem 0" id="tokenomics">
        <Heading src={tokenHeading} />  
      <Container maxWidth="xl" sx={{paddingTop:"30px"}}>
        <MainGrid container spacing={3}>
          <Grid item xs={12} md={6}>
            <TWrapper >
              <Name>Supply</Name>
              <Value>TBA</Value>
            </TWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <TWrapper >
              <Name>Taxes</Name>
              <Value>TBA</Value>
            </TWrapper>
          </Grid>
          <Grid item xs={12} md={6}>
            <TWrapper>
              <Name>
              Slippage
              </Name>
              <Value>
            TBA
              </Value>
            </TWrapper>
          </Grid>
         
          <Grid item xs={12} md={6}>
            <TWrapper>
              <Name>
              Initial Liquidity
              </Name>
              <Value>
              TBA
              </Value>
            </TWrapper>
          </Grid>
         
          <Grid item xs={12}>
            <TWrapper border="1px solid #FBFB07">
              <Name Namecolor="#ffffff">Contract</Name>
              
              <Value href="#" target="_blank">
              TBA
              </Value>
              {/* <div style={{ display: "flex", alignItems: "self-start" }} color="8846E6">
                <Textarea ref={textAreaRef} value="TBA" />
                <img onClick={copyToClipboard} style={{ cursor: "pointer" }} src={copy} />
                {copySuccess}
              </div> */}
            </TWrapper>
          </Grid>
        </MainGrid>
      </Container>
    </SectionWrapper>
  );
};

export default Index;
