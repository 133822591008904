import { Container } from "@mui/material";

import {
  FooterImage,
  LogoImage,
  LogoText,
  P,
  SectionSmallHeading,
  SectionSubHeading,
  SectionWrapper,
} from "../../../Styles/style";
import apple from "../../../../assets/images/solokiimg.png";
import Twitter from "../../../../assets/images/twitter.png";
import Telegram from "../../../../assets/images/telegram.png";
import Medium from "../../../../assets/images/mediumsolki.png";



import {
  IconsTwitter,
  // IconsTelegram,
  IconsMedium,
  Contactus,
  SocialsDIv,
  SocialLinks,
  MainDiv,
  Copy,
  Issue,
  SocialMedia,
  Follow,
  SocialMediaLinks,
  Single,
  Wrapper,
} from "./Styles";
import styled from "@emotion/styled";
const AppSIde = styled.div`
display  :flex ;
@media screen and (max-width:499px){
    display: grid;
  }
`
const SocialsSide = styled.div`
  
`
const SocialMediaDiv = styled.div`
  display: flex;
 
`
const SocialLiks = styled.a`
`
const SocailImg = styled.img`
padding-right: 20px;
`
const LogoImg = styled.img`
width:100%;
height: auto;
max-width:150px;
`
const ParaSapn = styled.span`
padding-left: 20px;
`
const S6_Footer = () => {
  return (
    <div>
      <SectionWrapper>
        <Container maxWidth="xl">
          <Wrapper>
            <Issue>
              <AppSIde>
                <LogoImg src={apple} /><br />
                <ParaSapn>
                <P maxWidth="60%">
                Bloki is a meme token launched on the Blast Network aiming for community-driven growth and empowerment.

                </P>
                <P maxWidth="60%">Copyright © 2024 BLOKI. All Right Reserved
                </P>
                </ParaSapn>
              </AppSIde>
              <SocialsSide>
                <SectionSmallHeading p="2rem 0">
                  Social Media
                </SectionSmallHeading>
                <SocialMediaDiv>
                  <SocialLiks href="https://twitter.com/blokionblast" target="_blank">
                    <SocailImg src={Twitter} />
                  </SocialLiks>
                  <SocialLiks href="https://t.me/blokiblast" target="_blank">
                    <SocailImg src={Telegram} />
                  </SocialLiks>
                </SocialMediaDiv>
              </SocialsSide>
            </Issue>
           
          </Wrapper>
        </Container>

      </SectionWrapper>
    </div>
  );
};

export default S6_Footer;
