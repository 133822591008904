import React, { useEffect } from "react";

import { Container, Grid } from "@mui/material";

import Aos from "aos";
import "aos/dist/aos.css";

import { MainPageHeading, MainPageHeadingSubHeading } from "../../../Styles/style";
import Solana from "../../../../assets/images/solana.png"
import Followtwitter from "../../../../assets/images/followtwitter.png"
import Jointelegram from "../../../../assets/images/jointelegram.png"
import heroHeading from "../../../../assets/images/heroHeading.png"
import { Wrapper, Para } from "./styles";
import styled from "@emotion/styled";

const ReadMore = styled.a`
  text-decoration: none;
  cursor: pointer;
  background-color: ${(props) => (props.bgcolor ? props.bgcolor : "#000000")};
  margin-right: 20px;
  border-radius: 42px;
  padding: ${(props) => (props.p ? props.p : "13px 20px 4px 20px")};
  font-size: 14px;
  @media screen and (max-width: 899px) {
    /* padding: ${(props) => (props.p599 ? props.p599 : "5px 27px")}; */
  }
  &:hover {
    opacity: 0.9;
  }
  @media screen and (max-width:399px){
    margin: 5px 0;
    margin-right: 0px;
}
`;
const SOcialImg = styled.img`
width:100%;
height: auto;
max-width: 120px;
`
const Heading = styled.img`
width:100%;
height: auto;
max-width: 500px;
`
const SocialMedia = styled.div`
display: flex;
align-items: center;
@media screen and (max-width:399px){
  display: grid;
}
`
const S1_Header = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
    window.addEventListener("load", function () {
      Aos.init();
    });
  }, []);
  return (
    <div>
      <Wrapper>
        <Container maxWidth="xl">
          <Grid container>
            <Grid xs={12} md={6}>
              <Heading  src={heroHeading} />
              <Para maxWidth="80%">Get Ready For Liftoff With Bloki, The Meme Token That's 
Taking The Blast Network By Storm!
<br/>
<br/>
Fueled by the power of the Blast Network's rapid transactions 
and low fees, Bloki is poised to become the next big thing in 
the meme token universe. Join our vibrant community and be 
a part of something truly revolutionary. Buckle up and prepare 
for an unforgettable ride!</Para>
             <SocialMedia>
             <ReadMore><SOcialImg src={Solana}/></ReadMore>
             <ReadMore href="https://twitter.com/blokionblast" target="_blank" p="8px 20px 3px 20px" bgcolor="#03A9F4"><SOcialImg src={Followtwitter}/> </ReadMore>
             <ReadMore  href="https://t.me/blokiblast" target="_blank" p="8px 20px 3px 20px" bgcolor="#37AEE2"><SOcialImg src={Jointelegram}/> </ReadMore>
             </SocialMedia>
             </Grid>
          </Grid>
        </Container>
      </Wrapper>

    </div>
  );
};

export default S1_Header;
