import styled from "@emotion/styled";
import blokiBGImg from "../../../../assets/images/MianBg.webp"

const Wrapper = styled.div`
  min-height:100vh;
  background-image: url(${blokiBGImg});
  background-repeat: no-repeat;
  background-position:100%;
  background-size: cover;
  display: flex;
  align-items: center;
  z-index: 1;
  @media screen and (min-width: 1700px) {
    align-items: center;
  }
  @media screen and (max-width: 700px) {
  background-position: top right;
  }
`;
const Para = styled.p`
  font-weight: ${(props) => (props.fw ? props.fw : "")};
  font-family: "Poppins-Regular";
  font-size: ${(props) => (props.fs ? props.fs : "18px")};
  color: ${(props) => props.theme.textOpposite};
  max-width: ${(props) => (props.maxWidth ? props.maxWidth : "100%")};
  text-align: ${(props) => (props.align ? props.align : "left")};
  margin: ${(props) => (props.m ? props.m : "20px 0")};
  padding: ${(props) => (props.p ? props.p : "")};
  @media (max-width: 600px) {
    font-size: ${(props) => (props.fs600px ? props.fs600px : "")};
    max-width: 100%;
  }
  @media (max-width: 1440px) and (max-height: 620px) {
    padding-top: ${(props) => (props.pt630 ? props.pt630 : "0px")};
  }
`;
const Chart = styled.a`
  text-decoration: none;
  cursor: pointer;
  background-color: #16c186;
  box-shadow: ${(props) =>
    props.bs ? props.bs : "0px 19px 50px 45px rgb(22 193 134 / 20%)"};
  color: ${(props) => (props.color ? props.color : "#ffca1b")};
  margin-right: 20px;
  border-radius: 10px;
  padding: 8px 40px;
  font-family: "Poppins-SemiBold";
  font-size: 14px;
  @media screen and (max-width: 899px) {
    padding: ${(props) => (props.p599 ? props.p599 : "5px 27px")};
  }

  /* box-shadow: ${(props) =>
    props.bs ? props.bs : "inset 0px 2px 5px 0px #FED0AE"}; */
  &:hover {
    opacity: 0.9;
  }
`;
const BottombUTTON = styled.div`
  padding: 20px 0 20px 0;
  @media screen and (max-width: 599px) {
    /* bottom: -80px; */
  }
`;
const TransactionBuyLinks = styled.div`
  background-color: #16c186;
  padding: 20px 0;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;
const Dextool = styled.a`
  padding: 0px 30px;
  @media screen and (max-width: 450px) {
    padding: 0px 10px;
  }
`;
const Uniswap = styled.a`
  padding: 0px 30px;
  @media screen and (max-width: 450px) {
    padding: 0px 10px;
  }
`;
const Etherscan = styled.a`
  padding: 0px 30px;
  @media screen and (max-width: 599px) {
    padding: 0px 10px;
  }
`;

const HeaderSubImage = styled.img`
  width: 100%;
  height: auto;
  max-width: 180px;
`;
const LeftHand = styled.div`
  position: absolute;
  left: 0;
  bottom: 40px;

  @media screen and (max-width: 630px) {
    bottom: 20px;
  }
  @media screen and (max-width: 360px) {
    bottom: 0px;
  }
`;
const LeftHandImage = styled.img`
  width: "100%";
  height: auto;
  max-width: 400px;

  @media screen and (max-width: 1300px) {
    max-width: 250px;
  }
  @media screen and (max-width: 599px) {
    max-width: 150px;
  }
`;
const RightHand = styled.div`
  position: absolute;
  right: 0;
  bottom: 100px;
  @media screen and (max-width: 630px) {
    bottom: 50px;
  }
  @media screen and (max-width: 360px) {
    bottom: 30px;
  }
`;
const RightHandImage = styled.img`
  width: "100%";
  height: auto;
  max-width: 400px;
  @media screen and (max-width: 1300px) {
    max-width: 250px;
  }
  @media screen and (max-width: 599px) {
    max-width: 150px;
  }
`;
export {Wrapper,Chart,BottombUTTON,TransactionBuyLinks,Dextool,Uniswap,Etherscan,HeaderSubImage,LeftHand,LeftHandImage,RightHand,RightHandImage,Para};
