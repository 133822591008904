import styled from "@emotion/styled";
import React from "react";
import { PageWrapper } from "../../Styles/style";
import S1_Header from "./S1_Header";
import S2_Aboutus from "./S2_Aboutus";
import S6_Footer from "./S6_Footer";
import Tokenomics2 from "./S3_Tokenomics";


const MainPage = (props) => {
  return (
    <PageWrapper>
        <S1_Header />
      <S2_Aboutus />
      <Tokenomics2 />
      <S6_Footer />
    </PageWrapper>
  );
};

export default MainPage;
